import useSWR from "swr";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Map, ZoomControl, Marker, Overlay, Point } from "pigeon-maps";
import { Link } from "wouter";
import { HiOutlineAdjustmentsHorizontal } from "react-icons/hi2";
import { quest } from "../dto/interfaces";
import { fetcher } from "../utils/fetcher";
import Input from "../components/Form/Input";
import QuestBlock from "../components/QuestBlock";
import styles from "./home.module.scss";
import Button from "../components/Button/Button";
import Header from "../components/Header/Header";
import { useUserStore } from "../stores/userStore";

export default function Home() {
  const { authDetails } = useUserStore((state) => state);

  const { data: availableQuests } = useSWR<quest[]>(
    `${process.env.REACT_APP_API_URL}/quests`,
    fetcher
  );

  const initialAnchor: Point = [0, 0];
  const initialQuest = {
    id: 0,
    quest_name: '',
    quest_image: '',
    organization_id: 0,
    location_id: 0,
    quest_description: '',
    quest_deadline: '',
    adventurer_limit: ''
  };
  const [center, setCenter] = useState<[number, number]>([20, 0]);
  const [zoom, setZoom] = useState(2);
  const [overlay, setOverlay] = useState({
    anchor: initialAnchor,
    quest: initialQuest
  });
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleMarker = (e: { event?: Event; anchor?: Point }, quest: any) => {
    setOverlay({
      anchor: e.anchor || initialAnchor,
      quest: quest
    });
    setIsOverlayVisible(!isOverlayVisible);
  };

  return (
    <>
      <Header user={authDetails} showQuests />
      <main>
        <div className={styles.searchContainer}>
          <div className={styles.searchField}>
            <Input type="search" placeholder="Find a Quest" />

            <Button rounded>
              <HiOutlineAdjustmentsHorizontal size={24} />
            </Button>
          </div>

          <div className={styles.searchFilters}>
            <div>
              <img
                src="assets/images/icons/icon-trees.svg"
                className={styles.filterImage}
                alt=""
              />
              Trees
            </div>

            <div>
              <img
                src="assets/images/icons/icon-waves.svg"
                className={styles.filterImage}
                alt=""
              />
              Water
            </div>

            <div>
              <img
                src="assets/images/icons/icon-soil.svg"
                className={styles.filterImage}
                alt=""
              />
              Soil
            </div>

            <div>
              <img
                src="assets/images/icons/icon-air.svg"
                className={styles.filterImage}
                alt=""
              />
              Air
            </div>

            <div>
              <img
                src="assets/images/icons/icon-hiker.svg"
                className={styles.filterImage}
                alt=""
              />
              Hiking
            </div>

            <div>
              <img
                src="assets/images/icons/icon-diver.svg"
                className={styles.filterImage}
                alt=""
              />
              Diving
            </div>

            <div>
              <img
                src="assets/images/icons/icon-kayak.svg"
                className={styles.filterImage}
                alt=""
              />
              Paddling
            </div>

            <div>
              <img
                src="assets/images/icons/icon-boat.svg"
                className={styles.filterImage}
                alt=""
              />
              Boating
            </div>
          </div>
        </div>

        <div className={styles.container}>
          <div className={styles.map}>
            <Map
              height={500}
              center={center}
              zoom={zoom}
              onBoundsChanged={({ center, zoom }) => {
                setCenter(center);
                setZoom(zoom);
              }}
            >
              <ZoomControl />
              {availableQuests &&
                availableQuests.map((quest: quest) => (
                  <Marker
                    width={50}
                    anchor={[
                      quest.locations ? quest.locations[0].coordinates[0] : 0,
                      quest.locations ? quest.locations[0].coordinates[1] : 0
                    ]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />
                ))}

              {isOverlayVisible && (
                <Overlay anchor={overlay.anchor} offset={[35, 0]}>
                  <div className={styles.marker_container}>
                    <h1 className={styles.marker_container_heading}>
                      {overlay.quest && overlay.quest.quest_name}
                    </h1>
                    <p>{overlay.quest && overlay.quest.quest_description}</p>
                    <p>
                      deadline:{' '}
                      {overlay.quest &&
                        new Date(
                          overlay.quest.quest_deadline
                        ).toLocaleDateString()}
                    </p>

                    <Link href={`/quests/${overlay.quest.id}`}>
                      more detail
                    </Link>
                  </div>
                </Overlay>
              )}
            </Map>
          </div>

          <ErrorBoundary
            fallback={
              <p>
                There was an error while attempting to fetch available quests.
              </p>
            }
          >
            {/* <Suspense fallback={<div>Loading quests...</div>}> */}
            <div className={styles.quests}>
              <div className={styles.questsContainer}>
                {availableQuests &&
                  availableQuests.map((quest: quest) => (
                    <QuestBlock
                      questData={quest}
                      orientation="vertical"
                      key={Math.random()}
                    />
                  ))}
              </div>
            </div>
            {/* </Suspense> */}
          </ErrorBoundary>
        </div>
      </main>
    </>
  );
}
