"use client";
import { Suspense, useEffect, useRef, useState } from "react";
import useSWR from "swr";
import { Link, useParams } from "wouter";
import Markdown from "react-markdown";
import { FaArrowRightLong } from "react-icons/fa6";
import { Map, ZoomControl, Marker, Overlay, Point } from "pigeon-maps";
import { quest, adventurer_quest } from "../dto/interfaces";
import Button from "../components/Button/Button";
import Modal from "../components/Modal/Modal";
import { fetcher } from "../utils/fetcher";
import { questTypeSelector } from '../utils/quest_type_selector';
import { Auth } from "aws-amplify";
import { useUserStore } from "../stores/userStore";
import Header from "../components/Header/Header";
import styles from "./quest_details.module.scss";

const QuestDetails = () => {
  const { authDetails } = useUserStore((state) => state);

  const username = authDetails?.getUsername();
  const params = useParams();
  // const fileInputRef = useRef<HTMLInputElement>(null);
  const BeingQuestModalRef = useRef<any>();
  const [isSignUpSuccessful, setSignUpSuccessful] = useState(false);

  const {
    data: adventurerQuestData,
    error: adventurerQuestError,
    isLoading: adventurerQuestLoading
  } = useSWR<adventurer_quest>(
    username ? `${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}` : null,
    fetcher
  );

  const {
    data: questData,
    error,
    isLoading
  } = useSWR<quest>(
    !adventurerQuestData?.quest_id ? `${process.env.REACT_APP_API_URL}/quests/${params.id}` : null,
    fetcher
  );

  const quest: quest | undefined = adventurerQuestData?.quest ? adventurerQuestData.quest : questData;

  const initialAnchor: Point = quest?.locations ? [quest?.locations[0].coordinates[0], quest?.locations[0].coordinates[1]] : [0, 0];
  const [center, setCenter] = useState<[number, number]>(initialAnchor);
  const [zoom, setZoom] = useState(4);
  const [overlay, setOverlay] = useState({
    anchor: initialAnchor,
    quest: quest
  });
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  useEffect(() => {
    setCenter(initialAnchor);
  }, [quest]);

  const handleMarker = (e: { event?: Event; anchor?: Point }, quest: any) => {
    setOverlay({
      anchor: initialAnchor,
      quest: quest
    });
    setIsOverlayVisible(!isOverlayVisible);
  };

  const closeQuestModal = () => {
    BeingQuestModalRef?.current?.setVisible(false);
  };

  const beginQuest = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/adventurers/${username}/quests/${params.id}/sign-up`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem("jwt")}`
        },
      });

      if (response.ok) {
        setSignUpSuccessful(true);
        closeQuestModal();
      } else {
        console.error("Failed to sign up for the quest");
      }
    } catch (error) {
      console.error("Error signing up for the quest:", error);
    }
  };

  async function handleSignIn() {
    try {
      await Auth.federatedSignIn();
    } catch (error) {
      console.log("Error signing in", error);
    }
  }

  if (isLoading || adventurerQuestLoading) {
    return <div>Loading...</div>;
  }

  if (error || adventurerQuestError) {
    return <div>Error loading quest details</div>;
  }

  if (!quest) {
    return <div>No quest found</div>;
  }

  const deadline = quest.quest_deadline
    ? new Date(quest.quest_deadline).toLocaleDateString()
    : "None";

  const difficultyRating = [];
  for (let i = 0; i < 5; i++) (
    difficultyRating.push(<div className={`${styles.difficultyMarker} ${i < quest.quest_difficulty ? styles['is-selected'] : ''}`} />)
  );

  return (
    <>
      <Header user={authDetails} />
      <main>
        <div className={styles.quest_image_hero}>
          <div className={styles.questTitleContainer}>
            <div>
              <h1 style={{ margin: 0 }}>{quest.quest_name}</h1>
              <h2 style={{ display: 'flex', columnGap: '48px' }}><span>{quest.locations && `${quest.locations[0].locality}, ${quest.locations[0].administrative_area}`}</span> <span>{quest.quest_deadline ? `Due: ${deadline}` : ''}</span></h2>
            </div>

            <div>
              {!username && <div><span onClick={handleSignIn} style={{ textDecoration: 'underline', color: 'blue', cursor: 'pointer' }}>Sign up</span> to go on an adventure!</div>}

              {username && !adventurerQuestData?.quest ? <Modal trigger={<Button>Apply to Quest <FaArrowRightLong size={14} /></Button>} title="Sign up for this quest" _ref={BeingQuestModalRef}>
                <>
                  <p>Please confirm you meet all the location and gear requirements for the quest: <strong>{quest.quest_name}</strong>.</p>
                  <Button callback={beginQuest}>Yes, sign me up!</Button>&nbsp;&nbsp;&nbsp;
                  <Button callback={closeQuestModal}>Actually, no...</Button>
                </>
              </Modal> : <Link href={`/quests/${params.id}/data`}><Button>Submit Data <FaArrowRightLong size={14} /></Button></Link>}
            </div>
          </div>
          <img src="/assets/images/quest-details-hero.jpg" alt="" />
        </div>

        <div className={styles.container}>
          <Suspense fallback={<div>Loading quest...</div>}>
            <div className={styles.content}>
              <div className={styles.overview}>
                {isSignUpSuccessful && <div className={styles.signup_success}>You have successfully signed up for the quest: <strong>{quest.quest_name}!</strong></div>}

                <h2>Overview</h2>

                <Markdown>{quest.quest_description}</Markdown>
              </div>

              <div className={styles.safety_information}>
                <div>
                  <h3 className={styles.subsectionTitle}>Adventure Type</h3>
                  <img src={questTypeSelector(quest.quest_type)} alt={quest.quest_type} className={styles.questTypeIcon} />
                  <span className={styles.questType}>{quest.quest_type}</span>
                </div>
                <div>
                  <h3 className={styles.subsectionTitle}>Level of Difficulty</h3>
                  <div className={styles.difficultyMarkerContainer}>{difficultyRating}</div>
                </div>
                {/* <div>
                  <h3 className={styles.subsectionTitle}>Equipment (see full list below)</h3>
                </div> */}
              </div>

              <div className={styles.risk_management}>
                <h3>Risk Management</h3>
                <Markdown>{quest.safety}</Markdown>
              </div>
            </div>

            <div className={styles.plusBackground}></div>

            <div className={styles.content}>
              <div>
                <Map
                  height={500}
                  center={center}
                  zoom={zoom}
                  onBoundsChanged={({ center, zoom }) => {
                    setCenter(center);
                    setZoom(zoom);
                  }}
                >
                  <ZoomControl />
                  <Marker
                    width={50}
                    anchor={[
                      quest.locations ? quest.locations[0].coordinates[0] : 0,
                      quest.locations ? quest.locations[0].coordinates[1] : 0
                    ]}
                    key={Math.random()}
                    onClick={(e) => handleMarker(e, quest)}
                  />

                  {isOverlayVisible && (
                    <Overlay anchor={overlay.anchor} offset={[35, 0]}>
                      <div className={styles.marker_container}>
                        <h1 className={styles.marker_container_heading}>
                          {overlay.quest && overlay.quest.quest_name}
                        </h1>
                        <p>{overlay.quest && overlay.quest.quest_description}</p>
                        <p>
                          deadline:{" "}
                          {overlay.quest &&
                            new Date(
                              overlay.quest?.quest_deadline || new Date()
                            ).toLocaleDateString()}
                        </p>
                      </div>
                    </Overlay>
                  )}
                </Map>
              </div>

              <div>
                <h3 className={styles.sectionTitle}>Study Motivation</h3>
                <Markdown>{quest.study_motivation}</Markdown>

                <h3 className={styles.sectionTitle}>Training</h3>
                <Markdown>{quest.training_requirement}</Markdown>
              </div>

              <div>
                <h3 className={styles.sectionTitle}>Equipment</h3>
                <Markdown>{quest.equipment}</Markdown>
              </div>
            </div>
          </Suspense>
        </div>
      </main>
    </>
  );
};

export default QuestDetails;
